.pixelartmaker {
    margin: 0 auto;
    text-align: center;
}

.pixelmap {
    width: 640px;
    height: 640px;
    margin: 0 auto;
    background-color: aliceblue;
    display: flex;
    flex-wrap: wrap;
    border: 2px solid var(--primary);
}

.pixelart-colorpicker {
    width: 270px;
    height: 50px;
    background-color: var(--primary);
    transition: all 0.5s;
    border-radius: none;
}

.pixelart-colorpicker:hover {
    background-color: var(--secondary);
}

.pixelart-menu {
    margin: 0 auto;
    width: 640px;
    font-size: 20px;
    border: 2px solid var(--primary);
    border-top: none;
    display: flex;
}

.pixelart-menu-color {
    width: 319px;
    height: 140px;
    border-right: 2px solid var(--primary);
}
.pixelart-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: var(--bgcolor);
    border: 2px solid var(--primary);
    background-color: var(--primary);
    font-size: 20px;
    width: 319px;
    transition: all 0.5s;
}

.pixelart-button:hover {
    color: var(--bgcolor);
    background-color: var(--secondary);
    border-color: var(--secondary);
}

