.memory-card {
    width: 60px;
    height: 120px;
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary);
    border: 5px solid var(--secondary);
    transform: rotateY(180deg);
    border-radius: 5px;
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    transition: 0.7s all;
}
/*
.memory-card:hover {
    transform: rotateY(0deg);
    background-color: transparent;
}
*/