.contact {
    margin: 0 auto;
    display: block;
    width: 70%;
    max-width: 1200px;
    justify-content: center;
    text-align: center;
}

.contact-form {
    display: block;
}

.contact-form-input {
    box-sizing: border-box;
    margin-top: 20px;
    width: 100%;
    max-width: 600px;
    height: 50px;
    border: 2px solid var(--primary);
    background-color: var(--bgcolor);
    color: var(--primary);
    font-size: 24px;
    text-indent: 10px;
    outline: none;
}

.contact-form-textarea {
    box-sizing: border-box;
    margin-top: 20px;
    width: 100%;
    max-width: 600px;
    height: 100px;
    border: 2px solid var(--primary);
    background-color: var(--bgcolor);
    color: var(--primary);
    font-size: 24px;
    text-indent: 10px;
    outline: none;
}

.contact-form-submit {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 600px;
    height: 40px;
    color: var(--bgcolor);
    border: 2px solid var(--primary);
    background-color: var(--primary);
    transition: all 0.5s;
    font-size: 24px;
}

.contact-form-submit:hover {
    color: var(--bgcolor);
    background-color: var(--secondary);
    border-color: var(--secondary);
}

@media only screen and (max-width: 700px) {
    .contact {
        width: 90%;
    }
}