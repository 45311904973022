.about {
    display: grid;
    grid-template-columns: 40% 40%;
    grid-template-rows: 90vh;
    column-gap: 5vw;
    justify-content: center;
    margin: 0 auto;
    max-width: 1200px;
}

.about-right {
    text-align: justify;
}

.about-left {
    padding-top: 2vh;
    padding-bottom: 40px;
}

.about-left div{
    max-height: 60vh;
}

.about-left img{
    width:100%;
    border-radius: 50%;
}

@media only screen and (max-width: 700px) {

    .about {
        display: block;
        width: 80%;
        margin: 0 auto;
    }

}