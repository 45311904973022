.home {
    margin: 0 auto;
    display: grid;
    width: 100%;
    max-width: 1200px;
    grid-template-columns: 40% 40%;
    grid-template-rows: 50vh 40vh;
    column-gap: 5vw;
    justify-content: center;
}

.home-left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.home-right {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.home-bottom {
    grid-column: 1 /3;
}

.home-right div {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    column-gap: 5%;
    row-gap: 5%;
}

.home-bottom div{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.home-skill {
    width: 100%;

}

.home-btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: var(--bgcolor);
    border: 2px solid var(--primary);
    background-color: var(--primary);
    width: 176px;
    height: 56px;
    transition: all 0.5s;
}

.home-btn:hover {
    color: var(--bgcolor);
    background-color: var(--secondary);
    border-color: var(--secondary);
}

@media only screen and (max-width: 700px) {

    .home {
        display: block;
    }

    .home-left, .home-right, .home-bottom {
        margin: 0 10px 0 10px;
        text-align: center;
    }

    .home-right {
        padding-top: 20px;
        padding-bottom: 20px;
        width: 90%;
        margin: 0 auto;
    }

    .home-bottom div {
        justify-content: center;
    }
    
}