:root {
  --bgcolor: #051622;
  --primary: #1BA098;
  --secondary: #DEB992;
  --code: white;
}

body {
  margin: 0;
  background-color: var(--bgcolor);
  color: var(--secondary);
  font-family: 'Roboto Mono', 'Helvetica', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.code-font-h {
  color: var(--code);
  font-family: 'Shadows Into Light';
  font-size: 40px;
  font-weight: 400;
}

.code-font-p {
  color: var(--code);
  font-family: 'Shadows Into Light';
  font-size: 20px;
  font-weight: 400;
}