.portfolio {
    margin: 0 auto;
    max-width: 640px;
    text-align: center;
}

.project {
    border: 2px solid var(--secondary);
    margin-top: 10px;
    padding: 0 10px 0 10px;
}

.project div {
    display: flex;
    justify-content: space-between;
}

.project div a {
    margin-bottom:10px;
    padding: 10px;
    color: var(--bgcolor);
    text-decoration: none;
    background-color: var(--primary);
}

.project div a:hover {
    background-color: var(--secondary);
}

@media only screen and (max-width: 700px) {

    .portfolio {
        max-width: 90%;
    }
}