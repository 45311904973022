.navbar {
    background-color: var(--bgcolor);
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    color: var(--primary);
    border-bottom: 2px solid var(--primary);
}

.nav-item {
    margin: 0 20px;
    color: var(--primary);
    text-decoration: none;
    font-weight: 700;
    font-size: 20px;
}

.nav-item-active {
    margin: 0 20px;
    color: var(--secondary);
    text-decoration: none;
    font-weight: 700;

}

.nav-item:hover {
    transition: all 0.5s ease-out;
    color: var(--secondary);
}

.navbar-icons {
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.navbar-icons a {
    margin-left: 10px;
    color: var(--primary);
}

.navbar-icons a:hover {
    color: var(--secondary);
}

.navbar-logo {
    width: 50px;
    padding-left: 20px;
}

.navbar-logo a{
    fill: var(--primary);
    text-decoration: none;
}

.navbar-logo a:hover{
    fill: var(--secondary);
}

.active {
    color: var(--secondary);
}

@media only screen and (min-width: 700px) {

    .nav-item {
        font-size: 16px;
    }

    .nav-home::after {
        content: ' Home';
    }

    .nav-about::after {
        content: ' About';
    }

    .nav-portfolio::after {
        content: ' Portfolio';
    }

    .nav-contact::after {
        content: ' Contact';
    }

}